export default [
  {
    icon: "GridIcon",
    title: "Inici",
    route: "foravila-home",
  },
  {
    icon: "PieChartIcon",
    title: "Estadístiques",
    route: "foravila-stats",
  },
];
