export default [
  {
    header: "Serveis",
  },
  {
    title: "Genèrics",
    route: "foravila-global-services-list",
    icon: "GlobeIcon",
  },
  {
    title: "Transfers",
    route: "foravila-transfers-list",
    icon: "TruckIcon",
  },
];
