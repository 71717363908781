export default [
  {
    header: "Reserves",
  },
  {
    icon: "BookOpenIcon",
    title: "Llistat",
    route: "foravila-bookings-list",
  },
  {
    icon: "CalendarIcon",
    title: "Calendari",
    route: "foravila-bookings-calendar",
  },
  {
    icon: "ClipboardIcon",
    title: "Planning",
    route: "foravila-bookings-planning",
  },
  {
    icon: "LockIcon",
    title: "Fiances",
    route: "foravila-bookings-security-deposits",
  },
  {
    icon: "DollarSignIcon",
    title: "Pagaments finals",
    route: "foravila-bookings-final-payments",
  },
  {
    icon: "AlertTriangleIcon",
    title: "Incidències",
    route: "foravila-incidences-list",
  },
  {
    icon: "ClipboardIcon",
    title: "Neteges",
    route: "foravila-cleanings-planning",
  },
  {
    icon: "MessageCircleIcon",
    title: "WhatsApp",
    route: "foravila-whatsapp",
  },
];
