export default [
  {
    header: "Administració",
  },
  {
    icon: "ShieldIcon",
    title: "Policia",
    route: "foravila-administration-police-reports",
  },
  {
    icon: "HomeIcon",
    title: "Hospedajes",
    route: "foravila-administration-hospedajes-reports",
  },
  {
    icon: "LayersIcon",
    title: "AEAT",
    route: "foravila-administration-aeat-reports",
  },
];
