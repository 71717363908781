export default [
  {
    header: "Configuració",
  },
  {
    icon: "ShieldIcon",
    title: "Polítiques",
    route: "foravila-booking-policies-list",
  },
  {
    icon: "PercentIcon",
    title: "Promocions",
    route: "foravila-promotions-list",
  },
  {
    icon: "PackageIcon",
    title: "Serveis",
    route: "foravila-services-list",
  },
  {
    icon: "CompassIcon",
    title: "Normes",
    route: "foravila-rules-list",
  },
  {
    icon: "MapPinIcon",
    title: "Llocs",
    route: "foravila-places-list",
  },
];
