export default [
  {
    header: "Comptabilitat",
  },
  {
    icon: "CreditCardIcon",
    title: "Pagaments",
    children: [
      {
        title: "Llistat",
        route: "foravila-accounting-payments-all",
      },
      {
        title: "Verificació",
        route: "foravila-accounting-payments-verify",
      },
      {
        title: "Tresoreria diària",
        route: "foravila-accounting-payments-daily-cash",
      },
      {
        title: "Targetes virtuals",
        route: "foravila-accounting-payments-virtual-cards",
      },
    ],
  },
  {
    icon: "DollarSignIcon",
    title: "Facturació",
    children: [
      {
        title: "Factures pendents",
        route: "foravila-accounting-billing-pending",
      },
      {
        title: "Factures emeses",
        route: "foravila-accounting-billing-invoices-list",
      },
    ],
  },
  {
    icon: "SettingsIcon",
    title: "Configuració",
    children: [
      {
        title: "Sèries de factures",
        route: "foravila-accounting-invoice-numberings-list",
      },
      {
        title: "C.C. generals",
        route: "foravila-accounting-global-ledger-accounts",
      },
      {
        title: "C.C. d'allotjaments",
        route: "foravila-accounting-accommodations-ledger-accounts",
      },
    ],
  },
];
