export default [
  {
    header: "Venda i Lloguer",
  },
  {
    icon: "HomeIcon",
    title: "Propietats",
    route: "foravila-real-estate-accommodations-list",
  },
  {
    icon: "UserIcon",
    title: "Clients",
    route: "foravila-real-estate-clients-list",
  },
];
