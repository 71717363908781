/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Group ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import home from "../items/home";
import accommodations from "../items/accommodations";
import bookings from "../items/bookings";
import services from "../items/services";
import users from "../items/users";
import realEstate from "../items/real-estate";
import settings from "../items/settings";
import support from "../items/support";
import administration from "../items/administration";

const productionMenu = [
  ...home,
  ...bookings,
  ...accommodations,
  ...services,
  ...administration,
  ...realEstate,
  ...users,
  ...settings,
];

const developmentMenu = [...productionMenu, ...support];

const menu =
  process.env.NODE_ENV === "development" ? developmentMenu : productionMenu;

export default menu;
