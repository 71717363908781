export default [
  {
    header: "Ajuda",
  },
  {
    icon: "SmileIcon",
    title: "Vuexy Docs",
    href: "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/guide",
  },
  {
    icon: "BoldIcon",
    title: "BootstrapVue Docs",
    href: "https://bootstrap-vue.org/",
  },
  {
    icon: "CodeIcon",
    title: "Bootstrap Docs",
    href: "https://getbootstrap.com/docs/5.0/getting-started/introduction/",
  },
];
