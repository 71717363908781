export default [
  {
    header: "Eines",
  },
  {
    icon: "CreditCardIcon",
    title: "Generador TPV",
    route: "foravila-tools-tpv-generator",
  },
];
