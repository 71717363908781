export default [
  {
    header: "Usuaris",
  },
  {
    icon: "LifeBuoyIcon",
    title: "Proveïdors",
    route: "foravila-providers-list",
  },
  {
    title: "Clients",
    route: "foravila-clients-list",
    icon: "BriefcaseIcon",
  },
];
