export default [
  {
    header: "Allotjaments",
  },
  {
    icon: "HomeIcon",
    title: "Llistat",
    route: "foravila-accommodations-list",
  },
  {
    icon: "UserXIcon",
    title: "Buits",
    route: "foravila-accommodations-empty-list",
  },
  {
    title: "Propietaris",
    route: "foravila-owners-list",
    icon: "KeyIcon",
  },
  {
    icon: "EditIcon",
    title: "Contractes",
    route: "foravila-contracts-list",
  },
];
